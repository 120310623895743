import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "p-grid" }
const _hoisted_5 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_6 = { for: "birthDate" }
const _hoisted_7 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_8 = { for: "weight" }
const _hoisted_9 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_10 = { for: "height" }
const _hoisted_11 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_12 = { for: "sex" }
const _hoisted_13 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_14 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_15 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_16 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_17 = { for: "fatPercentage" }
const _hoisted_18 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_19 = { for: "musclePercentage" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_HealzInput = _resolveComponent("HealzInput")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_MultiSelectBox = _resolveComponent("MultiSelectBox")!
  const _component_CardComponent = _resolveComponent("CardComponent")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.isAnthropometricDataNotReady)
    ? (_openBlock(), _createBlock(_component_EmptyState, {
        key: 0,
        heading: _ctx.$t('personData.emptyState.title'),
        icon: "icon-loading",
        text: _ctx.$t('personData.emptyState.message'),
        textAtBottom: true
      }, null, 8, ["heading", "text"]))
    : (_ctx.surveyCgmInitialFormDto)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_CardComponent, {
            cardTitle: _ctx.$t('personData.anthropometricDataPatient'),
            "edit-mode": _ctx.editMode
          }, {
            headingActions: _withCtx(() => [
              _withDirectives((_openBlock(), _createBlock(_component_Button, {
                class: _normalizeClass(["p-button-rounded p-button-only-icon p-button-only-icon--dark", _ctx.editMode ? 'p-disabled' : '']),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editMode = !_ctx.editMode))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("i", {
                    class: "icon-edit",
                    "aria-label": _ctx.$t('common.edit'),
                    "aria-hidden": "true"
                  }, null, 8, _hoisted_2)
                ]),
                _: 1
              }, 8, ["class"])), [
                [
                  _directive_tooltip,
                  _ctx.$t('common.edit'),
                  void 0,
                  { bottom: true }
                ]
              ])
            ]),
            cardContent: _withCtx(() => [
              (_ctx.surveyCgmInitialFormDto)
                ? (_openBlock(), _createElementBlock("form", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('personData.birthdate')), 1),
                        _createVNode(_component_Calendar, {
                          id: "birthDate",
                          modelValue: _ctx.surveyCgmInitialFormDto.birthDate,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.birthDate) = $event)),
                          class: _normalizeClass(_ctx.editMode ? '' : 'no-editable')
                        }, null, 8, ["modelValue", "class"])
                      ]),
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('personData.weight')), 1),
                        _createVNode(_component_HealzInput, {
                          id: "weight",
                          modelValue: _ctx.surveyCgmInitialFormDto.weight,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.weight) = $event)),
                          editable: _ctx.editMode,
                          type: "number"
                        }, null, 8, ["modelValue", "editable"])
                      ]),
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('personData.height')), 1),
                        _createVNode(_component_HealzInput, {
                          id: "height",
                          modelValue: _ctx.surveyCgmInitialFormDto.height,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.height) = $event)),
                          editable: _ctx.editMode,
                          type: "number"
                        }, null, 8, ["modelValue", "editable"])
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('personData.sex')), 1),
                        _createVNode(_component_Dropdown, {
                          id: "sex",
                          modelValue: _ctx.surveyCgmInitialFormDto.gender,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.gender) = $event)),
                          placeholder: "Selecciona",
                          options: _ctx.genderOptions,
                          "option-label": "label",
                          "option-value": "value",
                          class: _normalizeClass({ 'no-editable': !_ctx.editMode })
                        }, null, 8, ["modelValue", "options", "class"])
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        _createVNode(_component_MultiSelectBox, {
                          ref: "refBodyShape",
                          modelValue: _ctx.surveyCgmInitialFormDto.bodyShape,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.bodyShape) = $event)),
                          editMode: _ctx.editMode,
                          options: _ctx.bodyShapeOptions,
                          title: _ctx.$t('personData.bodyShapes.title')
                        }, null, 8, ["modelValue", "editMode", "options", "title"])
                      ]),
                      _createElementVNode("div", _hoisted_14, [
                        _createVNode(_component_MultiSelectBox, {
                          ref: "refAbsShape",
                          modelValue: _ctx.surveyCgmInitialFormDto.absShape,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.absShape) = $event)),
                          editMode: _ctx.editMode,
                          options: _ctx.absShapeOptions,
                          title: _ctx.$t('personData.absShapes.title')
                        }, null, 8, ["modelValue", "editMode", "options", "title"])
                      ]),
                      _createElementVNode("div", _hoisted_15, [
                        _createVNode(_component_MultiSelectBox, {
                          ref: "refComplexion",
                          modelValue: _ctx.surveyCgmInitialFormDto.complexion,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.complexion) = $event)),
                          editMode: _ctx.editMode,
                          options: _ctx.complexionOptions,
                          title: _ctx.$t('personData.complexion.title')
                        }, null, 8, ["modelValue", "editMode", "options", "title"])
                      ]),
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t('personData.fatPercentage')), 1),
                        _createVNode(_component_HealzInput, {
                          id: "fatPercentage",
                          modelValue: _ctx.surveyCgmInitialFormDto.fatPercentage,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.fatPercentage) = $event)),
                          editable: _ctx.editMode,
                          type: "number"
                        }, null, 8, ["modelValue", "editable"])
                      ]),
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t('personData.musclePercentage')), 1),
                        _createVNode(_component_HealzInput, {
                          id: "musclePercentage",
                          modelValue: _ctx.surveyCgmInitialFormDto.musclePercentage,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.surveyCgmInitialFormDto.musclePercentage) = $event)),
                          editable: _ctx.editMode,
                          type: "number"
                        }, null, 8, ["modelValue", "editable"])
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            footerActions: _withCtx(() => [
              _createVNode(_component_Button, {
                class: "p-button-secondary p-button-medium",
                label: _ctx.$t('common.cancel'),
                onClick: _ctx.onCancelEdition
              }, null, 8, ["label", "onClick"]),
              _createVNode(_component_Button, {
                class: "p-button p-button-info p-button-medium",
                loading: _ctx.waitSubmit,
                label: _ctx.$t('common.save'),
                onClick: _ctx.onSubmit
              }, null, 8, ["loading", "label", "onClick"])
            ]),
            _: 1
          }, 8, ["cardTitle", "edit-mode"])
        ]))
      : _createCommentVNode("", true)
}